<template>
  <el-form inline :model="selectModel" label-position="right">
    <el-form-item label="日期">
      <el-date-picker
        v-model="selectDatetime"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期" />
    </el-form-item>
    <el-form-item class="main-form-item-float-right">
      <el-button-group>
        <el-button type="primary" icon="el-icon-search" @click="queryTableData">查询</el-button>
      </el-button-group>
    </el-form-item>
  </el-form>

  <el-table
    :data="tableData"
    border
    stripe
    :default-sort="defaultSort"
    @sort-change="sort"
    style="width: 100%;">
    <el-table-column label="学校名称" prop="collegeName" min-width="200px" />
    <el-table-column min-width="100px">
      <template #header>
        洗衣营收
        <el-tooltip content="洗衣营收 = 余额营收 + 微信营收" placement="top">
          <i class="el-icon-info" />
        </el-tooltip>
      </template>
      <template #default="scope">
        {{ add(scope.row.localAmount, scope.row.wechatAmount) }}
      </template>
    </el-table-column>
    <el-table-column min-width="100px">
      <template #header>
        洗衣退款
        <el-tooltip content="洗衣退款 = 余额退款 + 微信退款" placement="top">
          <i class="el-icon-info" />
        </el-tooltip>
      </template>
      <template #default="scope">
        {{ add(scope.row.localRefund, scope.row.wechatRefund) }}
      </template>
    </el-table-column>
    <el-table-column label="余额营收" prop="localAmount" min-width="80px" />
    <el-table-column label="余额退款" prop="localRefund" min-width="80px" />
    <el-table-column label="微信营收" prop="wechatAmount" min-width="80px" />
    <el-table-column label="微信退款" prop="wechatRefund" min-width="80px" />
    <el-table-column label="充值" prop="rechargeAmount" min-width="80px" />
    <el-table-column label="订单数" prop="machineOrder" min-width="80px" />
    <el-table-column label="新增投递" prop="machineNum" min-width="80px">
      <template #default="scope">
        {{ scope.row.machineNumLast - scope.row.machineNumFirst }}
      </template>
    </el-table-column>
    <el-table-column label="使用频次/天" prop="machineOrders" min-width="120px">
      <template #header>
        使用频次/天
        <el-tooltip content="频次计算为 订单总数/最终机器数（查询结束时间的报表中的机器基数）/查询间隔天数" placement="top">
          <i class="el-icon-info" />
        </el-tooltip>
      </template>
      <template #default="scope">
        {{ scope.row.machineNumLast ? (scope.row.machineOrder/scope.row.machineNumLast/days).toFixed(2) : '-' }}
      </template>
    </el-table-column>
  </el-table>
  <div style="margin-top: 10px; text-align: right;">
    <el-pagination
      layout="prev, pager, next,jumper"
      :total="total"
      @current-change="applyCurrentPage" />
  </div>

  <div>提示：如果选择日期未生成报表，则所有数据按0计算。</div>
</template>

<script>
import { handleCatchStatus } from '../../api/handler';
import { formatDate } from '../../utils/datetime';
import { add } from '../../utils/fee';
import moment from 'moment';

export default {
  data() {
    return {
      days: 7,
      selectDatetime: [],
      selectModel: {},
      selectSort: null,
      defaultSort: {prop: 'id', order: 'descending'},
      tableData: [],
      total: 0,
      current: 1,
      dialog: {
        visibled: false,
        created: false,
        readonly: false,
        title: '',
        form: {},
        submit: () => true
      },
      colleges: []
    };
  },
  created() {
    this.applyDay7();
    this.queryTableData();
  },
  mounted() {
  },
  methods: {
    add,
    queryTableData() {
      this.applyDatetime();
      this.$api.getReportCollegeOrderTotal(this.selectModel)
        .then(data => {
          this.tableData = data;
        })
        .catch(handleCatchStatus);
    },
    applyDatetime() {
      if (this.selectDatetime && this.selectDatetime.length === 2) {
        this.selectModel.startTime = formatDate(this.selectDatetime[0]);
        this.selectModel.endTime = formatDate(this.selectDatetime[1]);
        this.days = moment(this.selectModel.endTime).diff(moment(this.selectModel.startTime), 'days');
      } else {
        this.selectModel.startTime = null;
        this.selectModel.endTime = null;
      }
    },
    applyDay7() {
      const now = moment().toDate();
      const day7 = moment().subtract(7, 'days').toDate();
      this.selectDatetime = [day7, now];
    }
  }
};
</script>
